/* body {
    color: black !important;
}

b, strong{
    font-weight: bolder !important;
} */

body {
    margin: 0;
    /*height: 100vh;*/
    color: #353535;
    font-family: 'Roboto', sans-serif;
  }

  .MuiRadio-colorSecondary.Mui-checked {
    color: #73B054 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #73B054 !important;
}