.App {
    font-family: var(--Poppins) !important;
    font-weight: 400;
  
    color: var(--text-color0);
    background-color: var(--background-color0);
  }
  
  .MuiTypography-root {
    font-family: var(--Poppins) !important;
  }
  
  .Transition-1 {
    transition: all 500ms cubic-bezier(0.67, 0.01, 0.34, 0.99);
  }
  
  .Transition-2 {
    transition: all 600ms ease;
  }
  
  .Transition-3 {
    transition: all 100ms ease;
  }
  
  .scrollTransition {
    opacity: 0;
    transform: translateY(100px);
  }
  
  .remove-underline:hover {
    text-decoration: none !important;
  }
  
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  
  a {
    text-decoration: none;
    color: rgb(var(--accent-color1));
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  img {
    display: block;
    object-fit: contain;
  }
  
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  button:hover,
  input[type="submit"]:hover {
    filter: brightness(1.18);
  }
  
  button:active,
  input[type="submit"]:active {
    position: relative;
    transform: translateY(0.2rem);
    transition: none !important;
  }
  
  /* ::selection {
    background-color: rgba(var(--accent-color1), 0.8);
    color: white;
  } */
  
  #root {
    --Poppins: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    --Fira: "Fira Mono", monospace;
  }
  
  /* start scrollbar */
  /* ::-webkit-scrollbar {
    width: 1rem;
    height: 1.125rem;
    background-color: var(--background-color0) !important;
  } */
  
  /* ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    height: 0.375rem;
    border: 0.25rem solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 0.438rem;
    border-radius: 0.5rem;
    background-color: var(--background-color2) !important;
    -webkit-box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%),
      inset 1px 1px 0px rgb(0 0 0 / 5%);
    box-shadow: inset -1px -1px 0px rgb(0 0 0 / 5%),
      inset 1px 1px 0px rgb(0 0 0 / 5%);
  } */
  
  /* fonts */
  
  /* Freight Big Pro */
  @font-face {
    font-family: "Fira Mono";
    font-weight: 700;
    src: local("fira_mono"),
      url("./fonts/fira_mono/FiraMono-Bold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Fira Mono";
    font-weight: 400;
    src: local("fira_mono"),
      url("./fonts/fira_mono/FiraMono-Regular.ttf") format("truetype");
  }
  
  /* fonts */
  @font-face {
    font-family: "Poppins";
    font-weight: 900;
    src: local("poppins"),
      url("./fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins";
    font-weight: 400;
    src: local("poppins"),
      url("./fonts/poppins/Poppins-Regular.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "Poppins";
    font-weight: 200;
    src: local("poppins"),
      url("./fonts/poppins/Poppins-ExtraLight.ttf") format("truetype");
  }

  .dark-mode {
    --background-color0: #19191e;
    --background-color1: #24242b;
    --background-color2: #2b2b30;
    --background-color3: #3f3f46;
  
    --accent-color0: 53, 177, 252;
    --accent-color1: 161, 166, 255;
    --accent-color2: 143, 149, 255;
  
    --text-color0: #d4d2d2;
    --text-color1: #e6e6e6;
    --text-color2: #f2f1f6;
  
    --navbar-color: rgba(36, 36, 43, 0.96);
  
    --box-shadow1: rgba(23, 22, 27, 0.8) 0px 28px 50px 0px;
  }
  
  .white-mode {
    --background-color0: #fff;
    --background-color1: #f3f5fc;
    --background-color2: #d7dae4;
    --background-color3: #bdc0c9;
  
    --accent-color0: 0, 166, 255;
    --accent-color1: 14, 23, 180;
    --accent-color2: 14, 23, 180;
  
    --text-color0: #232c33;
    --text-color1: #394147;
    --text-color2: #4f565c;
  
    --navbar-color: rgba(255, 255, 255, 0.96);
  
    --box-shadow1: rgba(17, 12, 46, 0.15) 0px 28px 50px 0px;
  }
  

div .resizer {  height: 20px; background: grey;border-radius: 5px; position:absolute; top: -10px; left: 0; cursor: se-resize;display: flex; justify-content: center; align-items: center; }
div .drag_drop { height: 25px; background: grey;border-radius: 5px; position:absolute; top: -10px; right: 0; cursor: grab;display: flex; justify-content: center; align-items: center;font-size: 13px;padding-left: 5px;padding-right: 5px; }
.pay_attention { position:absolute; bottom: -10px; right: 0; left: 0;  }
